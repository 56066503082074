import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import Router from 'next/router';

import formatEmailIfNotExistsInFirebase from 'helpers/format-email-if-not-exists-in-firebase/formatEmailIfNotExistsInFirebase';
import { encryptClient, decryptClient } from 'helpers/quick-hash/quick-hash';

import AppController from '../../base/App.controller';
import ModalStore from '../common/modals/ModalStore';
import PasswordFieldStore from '../common/passwordField/PasswordFieldStore';

import { OFF, ON } from 'constants/inputField';
import {
    LOGIN_INVALID_CREDENTIALS,
    LOGIN_SYSTEM_ERROR,
    PASSWORD_EXPIRED,
} from '../../constants/errors';
import { mfa } from '../../constants/mfa';
import { otp } from '../../constants/otp';

const { publicRuntimeConfig } = require('next/config').default();
const firebase = require('../../firebase');
const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class SignInStore {
    GenericErrorStore = new ModalStore();
    PasswordFieldStore = new PasswordFieldStore();

    constructor() {
        try {
            this.hasPasswordSynced = window.sessionStorage.getItem('hasPasswordSynced') === 'true';
            this.isDefaultUserSetUp =
                window.sessionStorage.getItem('isDefaultUserSetUp') === 'true';
            this.groupMfaLoginSynced =
                window.sessionStorage.getItem('groupMfaLoginSynced') === 'true';
            this.defaultUserAccount['id'] = window.sessionStorage.getItem('identityId') ?? '';
            this.defaultUserAccount['email'] = window.sessionStorage.getItem('email') ?? '';
        } catch (err) {
            console.error(err);
        }
    }

    @observable
    form = {
        email: '',
        password: '',
        rememberMe: false,
        mfaAuthCode: '',
        otp: '',
    };

    @observable
    mfaEnabled = false;

    @observable
    sendOTPToUser = false;

    @observable
    forceOnboard = false;

    @observable
    secreteKey = '';

    @observable
    enrollmentUrl = '';

    @observable
    errorMessage = '';

    @observable
    disableButton = false;

    @observable
    codeError = '';

    @observable
    step = 0;

    @observable
    authCode = '';

    @observable
    loading = false;

    @observable
    hasPasswordSynced;

    @observable
    defaultUserAccount = {
        id: '',
        email: '',
    };
    @observable
    isDefaultUserSetUp;
    @observable
    groupMfaLoginSynced;
    @observable
    isGroupSecret = OFF;

    @action
    updateField = (name, value) => {
        this.errorMessage = '';
        this.form[name] = value;
    };

    get isFormFilled() {
        return !!this.form.email && !!this.form.password;
    }

    @action
    setCodeErrorMessage = (errorMessage) => {
        this.errorMessage = errorMessage;
    };

    @action
    setLoadingAndDisable = (loading, disable) => {
        this.loading = loading;
        this.disableButton = disable;
    };

    @action
    setGroupSecret = (value) => {
        this.isGroupSecret = value;
    };

    @action
    handleSubmit = flow(
        function* (AppStore) {
            this.loading = true;

            try {
                window.sessionStorage.clear();
                yield this._signInFirebase(AppStore.scope);
                yield this.completeSignIn(AppStore);
            } catch (e) {
                const errorCode = [
                    'auth/wrong-password',
                    'auth/invalid-credential',
                    'auth/user-not-found',
                ].includes(e?.code)
                    ? LOGIN_INVALID_CREDENTIALS
                    : LOGIN_SYSTEM_ERROR;
                this.GenericErrorStore.openErrorModal(e, errorCode);
                console.error(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
    @action
    validateMFACode = flow(
        function* (AppStore) {
            try {
                this.loading = true;
                this.setCodeErrorMessage('');
                yield axios.get(
                    `/api/auth/validate-mfa-code?email=${encodeURIComponent(
                        window.sessionStorage.getItem('email'),
                    )}&code=${this.form.mfaAuthCode}&feature=${mfa.LOGIN}`,
                );
                yield this.handleLogin(AppStore);
            } catch (e) {
                if (e?.response?.data?.status === 'invalid-code') {
                    this.setCodeErrorMessage('invalidCode');
                } else {
                    this.setCodeErrorMessage('unableToVerify');
                }
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    completeEnrolment = flow(
        function* () {
            this.loading = true;
            try {
                if (this.isGroupSecret === ON) {
                    yield axios.post('/api/auth/enroll-group-mfa', {
                        email: window.sessionStorage.getItem('email'),
                        identityId: encodeURIComponent(window.sessionStorage.getItem('identityId')),
                    });
                } else {
                    yield axios.post('/api/auth/enroll-complete', {
                        code: this.form.mfaAuthCode,
                        email: window.sessionStorage.getItem('email'),
                        feature: mfa.LOGIN,
                        secreteKey: this.secreteKey,
                        identityId: encodeURIComponent(window.sessionStorage.getItem('identityId')),
                    });
                }
                this.setStep(4);
            } catch (error) {
                this.setCodeErrorMessage('invalidCode');
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
    @action
    setStep = (step) => {
        this.errorMessage = '';
        this.step = step;
    };
    @action
    requestOTP = flow(
        function* (AppStore) {
            try {
                this.sendOTPToUser = true;
                this.setCodeErrorMessage('');
                const body = {
                    email: window.sessionStorage.getItem('email'),
                    scope: AppStore.scope,
                    codeType: otp.LOGIN_MFA,
                };

                yield axios.post('/api/auth/send-otp-code', {
                    ...body,
                });
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            }
        }.bind(this),
    );

    @action
    resendOTPCodeToUser = flow(
        function* (AppStore) {
            try {
                yield axios.post('/api/auth/resend-otp-code', {
                    email: window.sessionStorage.getItem('email'),
                    scope: AppStore.scope,
                    codeType: otp.LOGIN_MFA,
                });
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            }
        }.bind(this),
    );

    @action
    validateOTPCode = flow(
        function* (AppStore) {
            try {
                this.setCodeErrorMessage('');
                this.setLoadingAndDisable(true, true);
                const res = yield axios.get(
                    `/api/auth/verify-otp-code?email=${encodeURIComponent(
                        window.sessionStorage.getItem('email'),
                    )}&code=${this.form.otp}&codeType=${otp.LOGIN_MFA}`,
                );
                if (res.data.isVerified) {
                    yield this.handleLogin(AppStore);
                } else {
                    this.setCodeErrorMessage('invalidCode');
                }
            } catch (e) {
                console.error(e);
                this.setCodeErrorMessage('unableToVerify');
            } finally {
                this.setLoadingAndDisable(false, false);
            }
        }.bind(this),
    );

    _signInFirebase = async (scope, _email, _password) => {
        const password = _password ?? this.form.password;
        const email = await formatEmailIfNotExistsInFirebase(
            _email || this.form.email,
            scope,
            password,
        );

        // generate a hash form password using window.crypto module
        const hashedPassword = await encryptClient(password);
        window.sessionStorage.setItem('shared-ua', hashedPassword);
        window.sessionStorage.setItem('active-ua', email);

        await firebase.auth().signInWithEmailAndPassword(email, password);

        return firebase.auth().currentUser.getIdToken();
    };

    _sessionlessSignin = async (AppStore) => {
        const password = await decryptClient(window.sessionStorage.getItem('shared-ua'));
        const token = await this._signInFirebase(
            AppStore.scope,
            window.sessionStorage.getItem('email'),
            password,
        );
        await axios.post(
            '/api/auth/temp-signin',
            {
                token: token,
                email: window.sessionStorage.getItem('email'),
                rememberMe: window.sessionStorage.getItem('rememberMe') === 'true',
            },
            { withCredentials: true },
        );
    };

    @action
    handleLogin = flow(
        function* (AppStore) {
            this.loading = true;
            try {
                // only if user can sign in, we can check if accounts are synced
                if (publicRuntimeConfig.userAccounts && !this.hasPasswordSynced) {
                    yield this._sessionlessSignin(AppStore);
                    yield Router.push('/user-accounts');
                } else if (publicRuntimeConfig.userAccounts && !this.isDefaultUserSetUp) {
                    yield this._sessionlessSignin(AppStore);
                    yield Router.push('/user-accounts/select-default');
                } else {
                    this.form.email = window.sessionStorage.getItem('email');
                    this.form.password = yield decryptClient(
                        window.sessionStorage.getItem('shared-ua'),
                    );
                    const token = yield this._signInFirebase(
                        AppStore.scope,
                        this.defaultUserAccount.email,
                    );

                    const email = this.defaultUserAccount.email || this.form.email;
                    window.sessionStorage.setItem('email', email);

                    yield axios.post(
                        '/api/auth/signin',
                        {
                            token: token,
                            email: window.sessionStorage.getItem('email'),
                            rememberMe: window.sessionStorage.getItem('rememberMe') === 'true',
                            identityId: this.defaultUserAccount.id,
                        },
                        { withCredentials: true },
                    );
                    yield Router.push('/home');
                }
            } catch (error) {
                const errorCode = LOGIN_SYSTEM_ERROR;
                this.GenericErrorStore.openErrorModal(error, errorCode);
            }
        }.bind(this),
    );
    @action
    completeSignIn = flow(
        function* (AppStore) {
            this.loading = true;
            try {
                window.sessionStorage.setItem('rememberMe', this.form.rememberMe);
                window.sessionStorage.setItem('email', this.form.email);
                this.defaultUserAccount['email'] = this.form.email;
                const res = yield axios.get(
                    `/api/auth/verify-credential?email=${encodeURIComponent(this.form.email)}`,
                );
                if (publicRuntimeConfig.userAccounts) {
                    const {
                        hasPasswordSynced,
                        defaultUserAccount,
                        isDefaultUserSetUp,
                        groupMfaLoginSynced,
                    } = res.data;
                    this.hasPasswordSynced = hasPasswordSynced;
                    this.defaultUserAccount = defaultUserAccount;
                    this.isDefaultUserSetUp = isDefaultUserSetUp;
                    this.groupMfaLoginSynced = groupMfaLoginSynced;
                }

                if (res.data.isInitialPassExpired) {
                    this.GenericErrorStore.openErrorModal(null, PASSWORD_EXPIRED);
                    return;
                }

                window.sessionStorage.setItem('hasPasswordSynced', res.data?.hasPasswordSynced);
                window.sessionStorage.setItem('isDefaultUserSetUp', res.data?.isDefaultUserSetUp);
                window.sessionStorage.setItem('identityId', res.data?.defaultUserAccount?.id);
                window.sessionStorage.setItem('groupMfaLoginSynced', res.data?.groupMfaLoginSynced);
                window.sessionStorage.setItem(
                    'email',
                    res.data?.hasPasswordSynced
                        ? res.data?.defaultUserAccount?.email
                        : this.form.email,
                );
                if (res.data.isPassExpired) {
                    AppStore.setUser({
                        email: this.form.email,
                        hasPasswordSynced: res.data.hasPasswordSynced,
                    });
                    Router.push('/update-password');
                    return;
                }
                this.forceOnboard = res.data.isForceMfaEnabled && !res.data.isMfaEnabled;
                if (this.forceOnboard && publicRuntimeConfig.mfaLogin) {
                    return Router.push('/mfa-onboarding');
                }
                if (publicRuntimeConfig.mfaLogin && res.data.isMfaEnabled) {
                    AppStore.setUser({ email: this.form.email });
                    if (
                        publicRuntimeConfig.userAccounts &&
                        res.data?.hasPasswordSynced &&
                        !res.data.groupMfaLoginSynced
                    ) {
                        return Router.push('/mfa-onboarding');
                    }
                    return Router.push('/verify-user-mfa');
                }
                yield this.handleLogin(AppStore);
            } catch (e) {
                const errorCode = LOGIN_SYSTEM_ERROR;
                this.GenericErrorStore.openErrorModal(e, errorCode);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleEnableMFA = flow(
        function* (AppStore) {
            this.loading = true;
            try {
                const res = yield axios.post('/api/auth/enroll', {
                    userEmail: window.sessionStorage.getItem('email'),
                    feature: mfa.LOGIN,
                });
                const { secretKey, qrCodeImageUrl } = res.data;
                this.secreteKey = secretKey;
                this.enrollmentUrl = qrCodeImageUrl;
                this.setStep(3);
            } catch (e) {
                console.error('Error when enrolling ', e);
                AppStore.errorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    resendCode = async (AppStore) => {
        this.setLoadingAndDisable(true, true);
        await this.resendOTPCodeToUser(AppStore);
        this.setLoadingAndDisable(false, false);
    };

    @action
    handleBackButton = () => {
        AppController.clearStorageSession();
        this.redirectToSignin();
    };

    @action
    redirectToSignin = () => {
        Router.push('/signin');
    };
}

export default SignInStore;
